var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main intelligence-detail" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-cell-group",
            [
              _c("van-cell", {
                attrs: {
                  title: "货物名称",
                  value: _vm.utils.isEffectiveCommon(_vm.cargoText)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "所属一级分类",
                  value: _vm.utils.isEffectiveCommon(_vm.firstLevelCategoryText)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "所属二级分类",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.secondLevelCategoryText
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "原产地",
                  value: _vm.utils.isEffectiveCommon(_vm.placeText)
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table" },
            [
              _vm._m(1),
              _vm._l(_vm.tableList, function(item, index) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "tr" },
                  [
                    _c("div", { staticClass: "fl" }, [
                      _vm._v(" " + _vm._s(index + 1) + " ")
                    ]),
                    _c("div", { staticClass: "fl" }, [
                      _vm._v(
                        " " + _vm._s(_vm.utils.moneyFormat(item.money)) + " "
                      )
                    ]),
                    _c("div", { staticClass: "fl" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.utils.dateFormat(item.updateTime)) +
                          " "
                      )
                    ]),
                    _c("div", { staticClass: "clearfix" }),
                    _c("van-divider")
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 商品价格更新记录 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "th" }, [
      _c("div", { staticClass: "fl" }, [_vm._v(" 序号 ")]),
      _c("div", { staticClass: "fl" }, [_vm._v(" 现货价（CNY/KG） ")]),
      _c("div", { staticClass: "fl" }, [_vm._v(" 更新日期 ")]),
      _c("div", { staticClass: "clearfix" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }