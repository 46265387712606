<template>
  <div class="van-main intelligence-detail">
    <div class="area-title">
      <p class="title">
        商品价格更新记录
      </p>
    </div>
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell title="货物名称" :value="utils.isEffectiveCommon(cargoText)" />
        <van-cell title="所属一级分类" :value="utils.isEffectiveCommon(firstLevelCategoryText)" />
        <van-cell title="所属二级分类" :value="utils.isEffectiveCommon(secondLevelCategoryText)" />
        <van-cell title="原产地" :value="utils.isEffectiveCommon(placeText)" />
      </van-cell-group>
      <div class="table">
        <div class="th">
          <div class="fl">
            序号
          </div>
          <div class="fl">
            现货价（CNY/KG）
          </div>
          <div class="fl">
            更新日期
          </div>
          <div class="clearfix" />
        </div>
        <div v-for="(item, index) in tableList" :key="item.id" class="tr">
          <div class="fl">
            {{ index + 1 }}
          </div>
          <div class="fl">
            {{ utils.moneyFormat(item.money) }}
          </div>
          <div class="fl">
            {{ utils.dateFormat(item.updateTime) }}
          </div>
          <div class="clearfix" />
          <van-divider />
        </div>
      </div>
    </div>
    <back-button />
  </div>
</template>

<script>
import BackButton from '@/components/back-button'
import { Cell, CellGroup, Divider, Button, Form, Field, Toast, Popup, Picker } from 'vant'
export default {
  components: {
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Toast.name]: Toast
  },
  data () {
    return {
      id: this.$route.query.id,
      firstLevelCategoryText: this.$route.query.cargoClassifyParentName,
      secondLevelCategoryText: this.$route.query.cargoClassifyName,
      cargoText: this.$route.query.cargoName,
      placeText: this.$route.query.place,
      tableList: []
    }
  },
  computed: {
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.api.intelligence.listUpCreditById(this.id).then(result => {
        this.tableList = result.data.value.list
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.intelligence-detail {
  .van-common-detail {
    margin-bottom: 50px;
  }
  .table {
    font-size: 14px;
    margin: auto 10px;
    padding-bottom: 60px;
    .th {
      background-color: rgb(242, 242, 242);
      border: 1px solid rgb(215, 215, 215);
      border-radius: 5px 5px 0px 0px;
      height: 46px;
      text-align: center;
      line-height: 46px;
      font-weight: 700;
      >div:first-child {
        width: 20%;
      }
      >div:nth-child(2) {
        width: 50%;
      }
      >div:nth-child(3) {
        width: 30%;
      }
    }
    .tr {
      height: 46px;
      text-align: center;
      line-height: 46px;
      font-weight: 400;
      >div:first-child {
        width: 20%;
      }
      >div:nth-child(2) {
        width: 50%;
      }
      >div:nth-child(3) {
        width: 30%;
      }
    }
    .van-divider {
      margin-top: 0px;
    }
  }
}
</style>
